import React from "react"
import styled from "@emotion/styled"
import { css, keyframes } from "@emotion/core"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { min } from "../utils/theme"

function HeroBanner() {
  const image = useStaticQuery(graphql`
    query {
      smallArch: file(relativePath: { eq: "arch-small.jpg" }) {
        childImageSharp {
          fixed(height: 700, width: 700) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      largeArch: file(relativePath: { eq: "arch.jpg" }) {
        childImageSharp {
          fixed(height: 800, width: 1600) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      helsinki: file(relativePath: { eq: "helsinki.jpg" }) {
        childImageSharp {
          fixed(height: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      field: file(relativePath: { eq: "field.jpg" }) {
        childImageSharp {
          fixed(height: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      ranta: file(relativePath: { eq: "ranta.jpg" }) {
        childImageSharp {
          fixed(height: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      tre: file(relativePath: { eq: "tre.jpg" }) {
        childImageSharp {
          fixed(height: 700) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const getPage = () => {
    return typeof window !== "undefined"
      ? window.location.pathname.replace(/\//g, "")
      : ""
  }

  const getContent = () => {
    const page = getPage()
    switch (page) {
      case "corporate-finance": {
        return (
          <>
            <SmallContainer>
              <Img
                style={{ width: "100%" }}
                fixed={image.field.childImageSharp.fixed}
                alt="pelto"
              />
              <SmallTextContainer>
                <Inner>
                  <HeadingText>Corporate Finance</HeadingText>
                  <Line />
                </Inner>
              </SmallTextContainer>
            </SmallContainer>
            <Overlay darkness={0.6} />
          </>
        )
      }

      case "kiitos":
      case "ota-yhteytta": {
        return (
          <>
            <SmallContainer>
              <Img
                fixed={image.helsinki.childImageSharp.fixed}
                style={{ width: "100%" }}
                alt="helsinki"
              />
              <SmallTextContainer>
                <Inner>
                  <HeadingText>Ota yhteyttä</HeadingText>
                  <Line />
                </Inner>
              </SmallTextContainer>
            </SmallContainer>
            <Overlay darkness={0.6} />
          </>
        )
      }

      case "muut-palvelut": {
        return (
          <>
            <SmallContainer>
              <Img
                fixed={image.ranta.childImageSharp.fixed}
                alt="ranta ilta-auringossa"
                style={{ width: "100%" }}
              />
              <SmallTextContainer>
                <Inner>
                  <HeadingText>Muut Palvelut</HeadingText>
                  <Line />
                </Inner>
              </SmallTextContainer>
            </SmallContainer>
            <Overlay darkness={0.6} />
          </>
        )
      }

      case "meista": {
        return (
          <>
            <Container>
              <Img
                fixed={image.tre.childImageSharp.fixed}
                alt="Tampere illalla"
                style={{ width: "100%" }}
              />
              <SmallTextContainer>
                <Inner>
                  <HeadingText>Hallitus</HeadingText>
                  <Line />
                </Inner>
              </SmallTextContainer>
            </Container>
            <Overlay darkness={0.5} />
          </>
        )
      }

      case "en": {
        const sources = [
          {
            ...image.smallArch.childImageSharp.fixed,
            media: `(max-width: 700px)`,
          },
          {
            ...image.largeArch.childImageSharp.fixed,
            media: `(min-width: 701px)`,
          },
        ]

        return (
          <>
            <Container>
              <Image fixed={sources} alt="People on balcony" />
              <TextContainer>
                <WideInner>
                  <HeadingText>
                    Our mission is to ensure corporate prosperity by helping
                    organizations with long and short term planning, corporate
                    acquisitions, financing and personnel incentives.
                  </HeadingText>
                  <Line />
                  <StyledLink to="/en#contact-us">Contact us →</StyledLink>
                </WideInner>
              </TextContainer>
            </Container>
            <Overlay darkness={0.5} />
          </>
        )
      }

      default:
        const sources = [
          {
            ...image.smallArch.childImageSharp.fixed,
            media: `(max-width: 700px)`,
          },
          {
            ...image.largeArch.childImageSharp.fixed,
            media: `(min-width: 701px)`,
          },
        ]

        return (
          <>
            <Container>
              <Image
                fixed={sources}
                alt="ihmisiä katsomassa parvekkeelta horisonttiin"
              />
              <TextContainer>
                <WideInner>
                  <HeadingText>
                    Missiomme on suomalaisten yritysten ja yrittäjien
                    menestymisen varmistaminen yritysjärjestelyjen, rahoituksen
                    ja henkilöstön palkitsemisen keinoin
                  </HeadingText>
                  <Line />
                  <StyledLink to="/ota-yhteytta">Ota yhteyttä →</StyledLink>
                </WideInner>
              </TextContainer>
            </Container>
            <Overlay darkness={0.5} />
          </>
        )
    }
  }

  return <Wrapper>{getContent()}</Wrapper>
}

export default HeroBanner

const Wrapper = styled.div`
  position: relative;
`

const Container = styled.div`
  position: relative;

  ${min("medium")(css`
    height: 700px;
    overflow: hidden;
  `)};
`

const SmallContainer = styled.div`
  position: relative;

  height: 400px;
  overflow: hidden;

  ${min("medium")(css`
    height: 500px;
  `)};
`

const SmallTextContainer = styled.div`
  position: absolute;
  top: 40%;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #00000080;
  opacity: ${p => p.darkness};
  z-index: 1;
`

const HeadingText = styled.h1`
  position: relative;
  z-index: 2;
  color: white;
  font-family: "Merriweather", serif;
  font-weight: normal;
  padding: 1rem;
  margin: 0;
  padding: 0.8rem;
  font-size: 18px;

  ${min("small")(css`
    font-size: 25px;
  `)};

  ${min("medium")(css`
    padding: 1.5rem;
    font-size: 30px;
  `)};

  ${min("large")(css`
    padding: 1.5rem;
    font-size: 40px;
  `)}
`

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  margin-top: 160px;

  ${min("small")(css`
    margin-top: 300px;
  `)};

  ${min("medium")(css`
    top: 40%;
    margin-top: 0;
  `)};

  ${min("large")(css`
    top: 35%;
    margin-top: 0;
  `)};
`

const Inner = styled.div`
  position: relative;
`

const WideInner = styled.div`
  position: relative;
  width: 90%;
`

const lineDrawing = keyframes`
  100% {
    width: 100%;
  };
`

const fadeInDown = keyframes`
  100% {
    transform: translateY(0);
    opacity: 1;
  };
`

const Line = styled.div`
  position: relative;
  z-index: 2;
  height: 2px;
  background: white;
  animation: ${lineDrawing} 1.5s;
  animation-fill-mode: forwards;
  width: 0;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-delay: 0.5s;
`

const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
  margin: 0.5rem 0;
  padding: 0 0.8rem;

  font-family: "Merriweather", serif;
  font-size: 16px;
  text-decoration: none;
  color: white;

  opacity: 0;
  animation: ${fadeInDown} 0.4s;

  animation-fill-mode: forwards;
  transition-timing-function: ease-in;
  animation-delay: 1.5s;

  transform: translateY(-20px);

  &:hover {
    text-decoration: underline;
  }

  ${min("small")(css`
    font-size: 18px;
  `)};

  ${min("large")(css`
    padding: 0 1rem;
    margin: 1rem;
  `)}
`

const Image = styled(Img)`
  display: block;
  float: right;
`
