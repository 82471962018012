import React, { useState, useEffect, useCallback } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import hamburger from "../images/hamburger.png"
import { min } from "../utils/theme"

function Navigation({ page }) {
  const [menuVisible, setVisible] = useState(false)

  useEffect(() => {
    if (menuVisible) {
      document.body.style = "width: 100%; position: fixed;"
    } else {
      document.body.style = ""
    }
  }, [menuVisible])

  const toggleMenu = useCallback(() => {
    setVisible(prevVisible => !prevVisible)
  }, [setVisible])

  return (
    <>
      {page === "en" ? null : (
        <TopLinks>
          <EnSiteLinkWrapper to="/en">
            <Flag role="img" aria-label="go to english site">
              🇬🇧
            </Flag>
            <ButtonText>In English</ButtonText>
          </EnSiteLinkWrapper>
          <TriggerButton onClick={toggleMenu}>
            <ButtonText>Menu</ButtonText>
            <Hamburger src={hamburger} alt="" />
          </TriggerButton>
        </TopLinks>
      )}
      <Menu menuVisible={menuVisible}>
        <MenuHeader>
          <TriggerButton aria-label="sulje menu" onClick={toggleMenu}>
            <ButtonText>Sulje</ButtonText>
            <Cross
              width="25"
              height="25"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.234 9l5.954-5.954L14.954.812 9 6.766 3.046.812.812 3.046 6.766 9 .812 14.954l2.234 2.234L9 11.234l5.954 5.954 2.234-2.234z"
                fill="black"
              />
            </Cross>
          </TriggerButton>
        </MenuHeader>
        <MenuLinks>
          <LinkWrapper>
            <Link to="/">Etusivu</Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link to="/corporate-finance">Corporate Finance</Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link to="/muut-palvelut#arvonmaaritys" onClick={setVisible}>
              Arvonmääritykset
            </Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link to="/muut-palvelut#rahoitus" onClick={setVisible}>
              Rahoitus
            </Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link to="/muut-palvelut#palkitseminen" onClick={setVisible}>
              Palkitseminen
            </Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link to="/meista">Hallitus</Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link to="/ota-yhteytta">Yhteydenottolomake</Link>
          </LinkWrapper>
        </MenuLinks>
      </Menu>
      {menuVisible ? <Overlay onClick={toggleMenu} /> : null}
    </>
  )
}

export default Navigation

const TopLinks = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: stretch;
  z-index: 3;

  ${min("medium")(css`
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  `)}
`

const TriggerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  background-color: white;
  border: none;
  outline: none;
  z-index: 2;
  cursor: pointer;
`

const EnSiteLinkWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0.7rem;
  padding: 0.2rem;
  background-color: white;
  z-index: 3;
  color: black;
  text-decoration: none;

  ${min("medium")(css`
    margin-top: 0;
    margin-right: 0.7rem;
    padding: 0.7rem;
  `)}
`

const Hamburger = styled.img`
  height: 20px;
  margin: 0.25rem;
  margin-left: 0.5rem;
`

const Cross = Hamburger.withComponent(`svg`)

const Flag = styled.span`
  font-size: 2rem;

  ${min("medium")(css`
    display: none;
  `)}
`

const ButtonText = styled.span`
  display: none;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;

  ${min("medium")(css`
    display: initial;
  `)}
`

const Menu = styled.aside`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  min-width: 100vw;
  background-color: #eeece6;

  transform: ${p => (p.menuVisible ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s;

  overflow-y: auto;

  z-index: 10;

  ${min("medium")(css`
    min-width: 400px;
  `)}
`
const MenuHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`

const MenuLinks = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1rem;
`

const LinkWrapper = styled.li`
  display: flex;
  align-items: center;
  padding: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;

  border-bottom: 1px solid #00000050;

  & > a {
    font-size: 18px;
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      border: none;
    }
  }
`
const Overlay = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
`
