import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"

import { min, max } from "../utils/theme"

export default function ContentCard({ imgProps, title, paragraphs, anchorId }) {
  return (
    <Wrapper id={anchorId}>
      {imgProps ? <Image {...imgProps} /> : null}
      <TextContainer>
        <Title>{title}</Title>
        {paragraphs ? paragraphs.map((p, i) => <Text key={i}>{p}</Text>) : null}
      </TextContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${min("medium")(css`
    flex-direction: row;
  `)};

  &:nth-of-type(odd) {
    ${min("medium")(css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `)};
  }
`

export const Title = styled.h2`
  padding: 0 1rem;

  ${max("mini")(css`
    font-size: 18px;
  `)}

  ${max("small")(css`
    font-size: 20px;
  `)}
`

export const Text = styled.p`
  padding: 0.5rem 1rem;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;

  ${min("small")(css`
    font-size: 18px;
  `)}
`

const Image = styled(Img)`
  height: 350px;
  width: 100%;

  ${min("medium")(css`
    min-height: 450px;
    width: auto;
    flex-basis: 50%;
  `)};
`

const TextContainer = styled.div`
  flex-basis: 50%;
  padding: 1rem 1rem 2rem;

  ${min("large")(css`
    padding: 1rem;
  `)};
`
