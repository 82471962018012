import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { VisuallyHidden } from "./utils/visuallyHidden"
import { min } from "../utils/theme"

const HeaderWrapper = styled.header`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  padding: 1rem;
`

const Logo = styled(Img)`
  height: 50px;
  width: 170px;
  margin: 0;

  ${min("medium")(css`
    height: 65px;
    width: 185px;
  `)};
`

const Header = ({ siteTitle }) => {
  const image = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-white.png" }) {
        childImageSharp {
          fluid(maxHeight: 65, maxWidth: 185) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper>
      <Link to="/">
        <VisuallyHidden>Takaisin kotisivulle</VisuallyHidden>
        <Logo fluid={image.logo.childImageSharp.fluid} alt="Credos oy" />
      </Link>
    </HeaderWrapper>
  )
}

export default Header
