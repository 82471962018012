import React from "react"
import styled from "@emotion/styled"
import Header from "./header"
import Navigation from "./navigation"
import Footer from "./footer"
import HeroBanner from "../components/heroBanner"

import "./layout.css"

const SiteWrapper = styled.div`
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
`

const MainContent = styled.main`
  max-width: 1500px;
  margin: 0 auto;
`

const Layout = ({ children }) => {
  const getPage = () => {
    return typeof window !== "undefined"
      ? window.location.pathname.replace(/\//g, "")
      : ""
  }

  const page = getPage()
  return (
    <>
      <Navigation page={page} />
      <SiteWrapper>
        <Header />
        <HeroBanner />
        <MainContent>{children}</MainContent>
        <Footer page={page} />
      </SiteWrapper>
    </>
  )
}

export default Layout
