import { css } from "@emotion/core"

export const breakpoints = {
  mini: 400,
  small: 600,
  medium: 900,
  large: 1250,
}

export const min = size => x => css`
  @media screen and (min-width: ${breakpoints[size] + 1}px) {
    ${x};
  }
`

export const max = size => x => css`
  @media screen and (max-width: ${breakpoints[size]}px) {
    ${x};
  }
`
