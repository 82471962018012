import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { min } from "../utils/theme"

const Footer = ({ page }) => (
  <FooterWrapper>
    <Container>
      <Contact>
        {page !== "en" ? (
          <>
            <Title>Yhteystiedot</Title>
            <p>Credos Oy, y-tunnus 1742695-6</p>
            <p>Kullaantie 3 A 22, 33960 Pirkkala</p>
            <p>puh. +358 400 403 593</p>
            <p>
              <EmailLink href="mailto:janne.kalliomaki@credos.fi">
                janne.kalliomaki@credos.fi
              </EmailLink>
            </p>
          </>
        ) : (
          <>
            <Title>Contact Information</Title>
            <p>Credos Oy, VAT number FI17426956</p>
            <p>Address: Kullaantie 3 A 22, 33960 Pirkkala, Finland</p>
            <p>tel. +358 400 403 593</p>
            <p>
              <EmailLink href="mailto:janne.kalliomaki@credos.fi">
                janne.kalliomaki@credos.fi
              </EmailLink>
            </p>
          </>
        )}
      </Contact>
      <LogoWrapper />
    </Container>
  </FooterWrapper>
)

export default Footer

const FooterWrapper = styled.footer`
  width: 100%;
  background-color: #eeece6;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
  padding: 1rem;

  ${min("medium")(css`
    max-width: 1250px;
    margin: 0 auto;
  `)}
`

const Contact = styled.address`
  padding: 1rem;
  border-left: 1px solid black;
  font-style: normal;
`

const Title = styled.h3`
  margin: 0 0 1rem 0;
`

const EmailLink = styled.a`
  color: black;
`

const LogoWrapper = styled.div`
  margin: 1rem;
`
